/*
 * @Author: wangjie
 * @Date: 2022-07-13 14:35:39
 * @Description: 收藏函数
 */
import httpRequest, { fzzykRequest } from '@/service'
import { ElMessage } from 'element-plus/lib/components'
export async function isCollect(
  isFavorValue: boolean,
  currentID: string,
  type?:number
) {
  if(process.env.VUE_APP_REQUIRE_LOGIN == 1){
    // 校验是否登录
    await fzzykRequest.get({ url: 'systemLoginCheck', params: { path: '' } })
  }
  const url = 'collectAction' + (type || '')
  httpRequest
    .post({
      url: url,
      params: {
        lawVersionId: currentID,
        caseId: currentID,
        judgmentId: currentID,
        favor: isFavorValue
      }
    }).then(()=>{
      if (isFavorValue) {
        ElMessage({
          message: '收藏成功',
          type: 'success'
        })
      } else {
        ElMessage({
          message: '取消收藏',
          type: 'warning'
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
